import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../../../translations';
import MySelect from '../../../../templates/MySelect';
import { getProjectsRequest } from '../../../../store/actions/projects';
import requestAllData from '../../../../helpers/requestAllData';
import Api from '../../../../Api';
import { getObjectsRequest } from '../../../../store/actions/objects';
import MyButton from '../../../../templates/MyButton';

function CheckupRequestModal() {
  const { t, lang } = useTranslation();
  const dispatch = useDispatch();
  const { projects } = useSelector((store) => store.projects);
  const { objects } = useSelector((store) => store.objects);

  const [project, setProject] = useState();
  const [object, setObject] = useState();

  const [filteredObjects, setFilteredObjects] = useState([]);

  const handleProject = (val) => {
    setProject(val);
    setObject(null);
    if (val) {
      setFilteredObjects(objects.items?.filter((i) => i.project_id === val) || []);
    } else {
      setFilteredObjects([]);
    }
  };

  const onNextClick = () => {
    window.open(`/z/client-id/${object}/${lang || 'ru'}`, '_blank');
  };

  useEffect(() => {
    if (!projects?.items?.length) {
      dispatch(getProjectsRequest(requestAllData));
    }
    if (!objects?.items?.length) {
      dispatch(getObjectsRequest(requestAllData));
    }
  }, []);

  return (
    <div className="checkup_request_modal">
      <p className="checkup_request_modal_title">{t('appointment_reg')}</p>
      <div className="checkup_request_modal_select_wrapper">
        <p>
          {t('project')}
          :
        </p>
        <MySelect
          wrapperClassName="checkup_request_modal_select"
          buttonText={t('choose_project')}
          data={projects?.items}
          value={project}
          onChange={handleProject}
          onlyDropDown
          t={t}
        />
      </div>
      <div className="checkup_request_modal_select_wrapper">
        <p>
          {t('object')}
          :
        </p>
        <MySelect
          wrapperClassName="checkup_request_modal_select"
          buttonText={t('choose_object')}
          data={filteredObjects}
          value={object}
          onChange={setObject}
          onlyDropDown
          t={t}
        />
      </div>
      <div className="checkup_request_modal_button">
        <MyButton
          text={t('next')}
          onClick={onNextClick}
          disabled={!project || !object}
        />
      </div>
    </div>
  );
}

export default CheckupRequestModal;
