import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import ReactCrop from 'react-image-crop';
import styles from '../css/index.module.scss';
import MyButton from '../../../templates/MyButton';
import useTranslation from '../../../translations';
import MyLoading from '../../../templates/MyLoading';
import MyImg from '../../../templates/MyImg';
import Api from '../../../Api';
import 'react-image-crop/dist/ReactCrop.css';
import MyInput from '../../../templates/MyInput';

function ViolPhotoModal({
  isOpen,
  closeFunction,
  viol,
  updateViols,
}) {
  const fileInputRef = useRef();
  const imgRef = useRef(null);
  const { t } = useTranslation();
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [imgSrc, setImgSrc] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [isAnyPhoto, setIsAnyPhoto] = useState(false);
  const [isFileChanged, setIsFileChanged] = useState(false);

  const onClosePress = () => {
    closeFunction();
    onResetSelectionClick();
    setSelectedFile(null);
    setIsFileChanged(false);
  };

  const onSaveClick = (onlyOriginal = true) => {
    const objToUpdate = {};

    if (isFileChanged) {
      objToUpdate.file = selectedFile;
    }

    if (onlyOriginal) {
      objToUpdate.crop = undefined;
      objToUpdate.zoom_params = '';
    } else {
      objToUpdate.crop = completedCrop;
      objToUpdate.zoom_params = JSON.stringify(completedCrop);
    }

    updateViols(undefined, objToUpdate);

    onClosePress();
  };

  const onResetSelectionClick = () => {
    setCrop(null);
    setCompletedCrop(null);
  };

  const onFileChooseClick = () => {
    onSelectFileClick();
    onResetSelectionClick();
  };

  const onSelectFileClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFile = (ev) => {
    if (ev.target.files?.length) {
      const [file] = ev.target.files;
      setSelectedFile(file);
      setImgSrc(URL.createObjectURL(file));
      setIsFileChanged(true);
      setIsAnyPhoto(true);
    } else {
      setSelectedFile();
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    if (viol.file) {
      handleFile({ target: { files: [viol.file] } });
    } else if (viol['picture-file']) {
      setImgSrc(Api.viols_img_url_no_request + viol['viol-id']);
      setIsAnyPhoto(true);
    }

    if (viol.crop) {
      setCrop(viol.crop);
      setCompletedCrop(viol.crop);
    } else if (viol.zoom_params) {
      setCrop(JSON.parse(viol.zoom_params));
      setCompletedCrop(JSON.parse(viol.zoom_params));
    }
  }, [viol, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeFunction}
      style={{ overlay: styles2.overlay, content: { ...styles2.content } }}
      closeTimeoutMS={200}
    >
      <div className={classNames('drawing_modal_wrapper', styles.viol_photo_modal)}>
        <div className="drawing_modal_container">
          <div className="buttons_row">
            <p className={styles.viol_photo_modal_title}>{t('viol_modal_title')}</p>
            <MyButton
              className="drawing_modal_button"
              iconUri="/images/close.png"
              onClick={onClosePress}
            />
          </div>

          {isAnyPhoto ? (
            <div className={styles.image_wrapper}>
              <div className={styles.image}>
                <ReactCrop
                  crop={crop}
                  onChange={(_, c) => setCrop(c)}
                  onComplete={(_, c) => setCompletedCrop(c)}
                  aspect={3 / 2}
                  keepSelection
                >
                  <img
                    ref={imgRef}
                    alt="Img"
                    src={imgSrc}
                  />
                </ReactCrop>
              </div>
              <div className={styles.image_buttons}>
                {completedCrop ? (
                  <MyButton
                    text={t('reset_selection')}
                    onClick={() => onResetSelectionClick()}
                  />
                ) : (
                  <MyButton
                    text={t('save_only_original')}
                    onClick={() => onSaveClick(true)}
                  />
                )}

                <MyButton
                  text={t('save_with_crop')}
                  onClick={() => onSaveClick(false)}
                  disabled={!completedCrop}
                />
                <MyButton
                  text={t('choose_another_file')}
                  onClick={onFileChooseClick}
                />
              </div>
            </div>
          ) : (
            <div className={styles.selectFileButton}>
              <MyButton
                text={t('choose_file')}
                onClick={onFileChooseClick}
              />
            </div>
          )}
        </div>
      </div>

      <MyInput
        innerRef={fileInputRef}
        name="file"
        wrapperClassName="viol_input_item hide"
        onChange={handleFile}
        type="file"
        accept="image/png, image/jpeg"
      />
    </Modal>
  );
}

const styles2 = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,0)',
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  content: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(255,255,255,0)',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 0,
  },
};

export default ViolPhotoModal;
