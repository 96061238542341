import React, { useEffect, useMemo, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import moment from 'moment';
import styles from './css/index.module.scss';
import useTranslation from '../../translations';
import MyCheckbox from '../../templates/MyCheckbox';
import Api from '../../Api';
import MyInput from '../../templates/MyInput';
import useInputState from '../../helpers/hooks/useInputState';
import MyButton from '../../templates/MyButton';
import CancelButton from '../CancelButton';
import { setCreatedDrawings } from '../../store/actions/drawings';
import htmlStaticText from '../../helpers/htmlStaticText';

function RegistryOfNotifications({
  document, documentType, activeLetterhead, iframeRef,
}) {
  const { t } = useTranslation();
  const [notifiedUsers, setNotifiesUsers] = useState([]);
  const [allocUsers, setAllocUsers] = useState([]);
  const [additionalRecipients, setAdditionalRecipients] = useInputState();
  const [loading, setLoading] = useState(false);

  const activeRecipientsCount = useMemo(() => {
    const mainRecipients = allocUsers.filter((item) => item.isChecked).length;
    const addRecipients = additionalRecipients.split('@').length - 1;
    return mainRecipients + addRecipients;
  }, [allocUsers, additionalRecipients]);

  const handleUsers = (user) => {
    user.isChecked = !user.isChecked;
    setAllocUsers([...allocUsers]);
  };

  const getAllocUsers = async (notifiedUsersParam) => {
    try {
      const users = [];
      const { data: sign } = await Api.getAllocs({
        'filter-val': {
          'project-id': document['project-id'],
        },
        limit: 100,
        offset: 0,
        'page-number': 0,
        'search-val': '',
      });
      users.push(...sign.items);

      // filtering alloc users
      const uniqueUsers = Array.from(new Set(users.map((a) => a.id)))
        .map((id) => users.find((a) => a.id === id));

      const populatedNotifiedUsers = notifiedUsersParam?.map((i) => {
        const us = uniqueUsers.find((el) => el['user-uid'] === i.address);
        if (us) {
          return { isFull: true, ...us, ...i };
        }
        return i;
      }) || [];

      setNotifiesUsers(populatedNotifiedUsers);
      setAllocUsers(uniqueUsers);
    } catch (e) {
      console.warn(e);
    }
  };

  const clean = () => {
    setAdditionalRecipients('', true);
    setAllocUsers((prev) => prev.map((i) => ({ ...i, isChecked: false })));
  };

  const sendInforming = async () => {
    if (!activeRecipientsCount) {
      return;
    }

    let addresses = `${
      allocUsers
        .map((item) => (item.isChecked ? item['user-uid'] : ''))
        .filter((item) => item)
        .join(', ')}`;

    if (additionalRecipients.trim().length) {
      addresses += `, ${additionalRecipients.trim()}`;
    }

    setLoading(true);

    let filePath = '';
    if (documentType === 'predpisan' && document.priority === 'low') {
      const nextCloudData = await saveToNextCloud();
      if (nextCloudData?.result === 'OK') {
        filePath = document['path-folder'];
      }
    }

    const payload = {
      tasks: [
        {
          'doc-type': documentType,
          'doc-id': document.id,
        },
      ],
      addresses: addresses.trim(),
      file_path: filePath,
    };

    const { data } = await Api.sendEmails(payload);
    setLoading(false);
    if (data.message === 'Ok') {
      clean();
      toast.success('Письма были успешно отправлены. Чуть позже данные отображатся в реестре', {
        position: 'top-right',
        autoClose: true,
        closeOnClick: true,
        draggable: true,
        theme: 'light',
        transition: Bounce,
      });
    }
  };

  const saveToNextCloud = async () => {
    try {
      if (!iframeRef?.current || !activeLetterhead || !document) return;

      const innerHtml = iframeRef?.current.contentWindow.document.body.innerHTML;
      const htmlStatic = htmlStaticText;

      const fullHtml = htmlStatic.replace('<!--PUT HERE-->', innerHtml);

      const payload = {
        'path-folder': document['path-folder'],
        filename: `${document['path-folder'].split('/').reverse()[1]}.pdf`,
        html: fullHtml,
      };

      const { data } = documentType === 'predpisan' ? await Api.savePrescriptionToNextCloud(payload) : await Api.saveActToNextCloud(payload);

      return data;
    } catch (e) {
      console.warn('Error while saving document to NextCloud', e);
    }
  };

  useEffect(() => {
    if (document?.notified_users) {
      setNotifiesUsers(document.notified_users);
    }

    getAllocUsers(document.notified_users).then();
  }, []);

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{t('registry_of_notifications')}</p>

      {documentType === 'predpisan' && document.priority === 'low' && (
        <p className={styles.titleInfo}>{t('registry_of_notifications_info')}</p>
      )}

      <p className={styles.titleSmall}>{t('notified_users')}</p>
      <div className={styles.checkboxes}>
        {notifiedUsers.length ? notifiedUsers?.map((user, index) => (
          <div key={`${user.id}${index}`} className={styles.checkbox}>
            <MyCheckbox
              value
              label={user.isFull ? `${user.fio} (${user.role}) ${user.address} - ${moment(user.udate).format('YYYY-MM-DD HH:mm')}` : `${user.address} - ${moment(user.udate).format('YYYY-MM-DD HH:mm')}`}
              className={styles.checkboxItem}
              labelClassName={styles.checkboxLabel}
              disabled
            />
          </div>
        )) : (
          <p className={styles.emptyText}>
            {t('empty')}
            .
          </p>
        )}
      </div>

      <p className={styles.titleSmall}>{t('project_members')}</p>
      <div className={styles.checkboxes}>
        {allocUsers?.map((user, index) => (
          <div key={`${user.id}${index}`} className={styles.checkbox}>
            <MyCheckbox
              onChange={() => handleUsers(user)}
              value={user.isChecked}
              label={`${user.fio} (${user.role}) ${user['user-uid']}`}
              className={styles.checkboxItem}
              labelClassName={styles.checkboxLabel}
            />
          </div>
        ))}
      </div>

      <p className={styles.titleSmall}>{t('additional_recipients')}</p>
      <MyInput
        infoClassName={styles.inputInfo}
        wrapperClassName={styles.input}
        value={additionalRecipients}
        onChange={setAdditionalRecipients}
        info={t('additional_rec_info')}
        placeholder="first@gmail.com, second@gmail.com"
      />

      <div className={styles.buttonsRow}>
        <MyButton
          className="modal_save_button"
          disabled={!activeRecipientsCount}
          text={t('send')}
          onClick={sendInforming}
          loading={loading}
        />

        {!loading && activeRecipientsCount ? (
          <CancelButton
            text={t('clean')}
            onClick={clean}
          />
        ) : null}
      </div>
    </div>
  );
}

export default RegistryOfNotifications;
