import React, { useEffect, useMemo, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCheckupsRequest, setCheckups } from '../../../store/actions/checkups';
import useTranslation from '../../../translations';
import MyButton from '../../../templates/MyButton';
import SinglePageHeader from '../../../components/SinglePageHeader';
import { CHECKUP_STATUSES, CHECKUP_STATUSES_LIST, ROLES } from '../../../helpers/constants';
import MySelect from '../../../templates/MySelect';
import { getObjectsRequest } from '../../../store/actions/objects';
import requestAllData from '../../../helpers/requestAllData';
import { getAllocsRequest } from '../../../store/actions/allocs';
import HeaderRow from './components/HeaderRow';
import ItemRow from './components/ItemRow';
import PagesPart from '../../../templates/PagesPart';
import getUniqueByKey from '../../../helpers/getUniqueByKey';
import MyModal from '../../../templates/MyModal';
import CheckupRequestModal from './components/CheckupRequestModal';

const limit = 15;

function Checkups() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const role = useSelector((store) => store.account.role);
  const profile = useSelector((store) => store.account.profile);

  const checkups = useSelector((store) => store.checkups.checkups);
  const checkupsLoading = useSelector((store) => store.checkups.checkupsLoading);

  const objects = useSelector((store) => store.objects.objects);
  const allocs = useSelector((store) => store.allocs.allocs);

  const [isCheckupRequestModalOpen, setIsCheckupRequestModalOpen] = useState(false);
  const [object, setObject] = useState();
  const [engineer, setEngineer] = useState(); // this is alloc id
  const [engineerUserId, setEngineerUserId] = useState(); // this is user id
  const [status, setStatus] = useState();
  const [statusName, setStatusName] = useState();
  const [page, setPage] = useState(0);

  const pagesCount = useMemo(() => {
    if (checkups?.page_number) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      return Math.ceil(checkups?.total / checkups?.limit) || 0;
    }
    return 0;
  }, [checkups]);

  const getCheckups = () => {
    const payload = {
      limit,
      offset: page * limit,
      build_objs_id: object,
      engineer_user_id: engineerUserId,
      status: statusName,
      sortdate: 'desc',
    };
    dispatch(getCheckupsRequest(payload));
  };

  const onStartClick = () => {
    navigate('/checklists');
  };

  const onCheckupRequestClick = () => {
    setIsCheckupRequestModalOpen(true);
  };

  const handleObject = (val) => {
    setPage(0);
    setObject(val);
  };

  const handleEngineer = (val) => {
    const currentAlloc = allocs.items.find((item) => item.id === val);
    setPage(0);
    setEngineer(val);
    setEngineerUserId(currentAlloc?.['user-id'] || '');
  };

  const handleStatus = (val) => {
    setStatus(val);
    const activeStatus = CHECKUP_STATUSES_LIST.find((item) => item.id === val);
    if (!activeStatus) {
      setStatusName('');
    } else {
      setStatusName(activeStatus.title);
    }
  };

  const changePage = (val) => {
    setPage(val);
  };

  const onPrescriptionNumberClick = (ev, id) => {
    ev.stopPropagation();
    navigate(`/prescriptions/edit?prescription_id=${id}`);
  };

  const onItemClick = (id, isOrder) => {
    if (isOrder) {
      navigate(`/checkups/request?checkup_id=${id}`);
    } else {
      navigate(`/checkups/edit?checkup_id=${id}`);
    }
  };

  useEffect(() => {
    getCheckups();
  }, [object, statusName, engineer, page]);

  useEffect(() => {
    if (allocs?.items?.length > 0 && profile?.id && role === ROLES.stroymanager) {
      // for engineers user id is set to his own id
      handleEngineer(profile.id);
    }
  }, [profile, role, allocs?.items?.length > 0]);

  useEffect(() => {
    dispatch(setCheckups({}));
  }, []);

  useEffect(() => {
    dispatch(getObjectsRequest(requestAllData));
    dispatch(getAllocsRequest(requestAllData));
  }, []);

  return (
    <div className="checkups_wrapper">
      <SinglePageHeader
        text={t('checkups')}
        withGoBack={false}
        rightComponent={role === ROLES.stroymanager
          ? <MyButton onClick={onStartClick} text={t('start_checkup')} />
          : role === ROLES.constructor
            ? <MyButton onClick={onCheckupRequestClick} text={t('checkup_request')} />
            : null}
        leftComponent={(
          <MyButton
            onClick={getCheckups}
            className="refresh_button"
            iconClassName="refresh"
            iconUri="/images/refresh.png"
            loading={checkupsLoading}
            loadingEqualsDisabled={false}
          />
        )}
      />

      <div className="filter_buttons_row">
        <MySelect
          wrapperClassName="filter_item"
          buttonText={t('all_objects')}
          data={objects?.items}
          value={object}
          onChange={handleObject}
          onlyDropDown
          t={t}
        />
        {role !== ROLES.stroymanager && (
          // for engineers user id is set to his own id
          <MySelect
            wrapperClassName="filter_item"
            buttonText={t('all_workers')}
            data={getUniqueByKey(allocs?.items, 'user-id')}
            value={engineer}
            onChange={handleEngineer}
            onlyDropDown
            t={t}
            showKey="fio"
          />
        )}
        <MySelect
          wrapperClassName="filter_item"
          buttonText={t('all_statuses')}
          data={CHECKUP_STATUSES_LIST}
          value={status}
          onChange={handleStatus}
          onlyDropDown
          t={t}
          withTranslation
        />
      </div>

      <div className="checkups_container">
        {checkups?.checkups?.length ? (
          <>
            <HeaderRow />
            {checkups.checkups.map((item, index) => (
              <ItemRow
                key={item.id}
                item={item}
                index={index}
                t={t}
                additionalCLick={onPrescriptionNumberClick}
                onItemClick={onItemClick}
              />
            ))}
          </>
        ) : !checkupsLoading && <p className="nothing_found_text">{t('nothing_found')}</p>}

        <PagesPart
          data={checkups}
          page={page}
          pagesCount={pagesCount}
          changePage={changePage}
        />
      </div>

      <MyModal
        isOpen={isCheckupRequestModalOpen}
        closeFunction={() => setIsCheckupRequestModalOpen(false)}
      >
        <CheckupRequestModal />
      </MyModal>
    </div>
  );
}

export default Checkups;
